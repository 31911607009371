<template>
	<div>
		<div
			class="home-grid"
			:class="`device_is_${deviceType} index`">
			<headLeft class="col-span-2 h" />
			<headRight v-bind="oneGame" />
			<Ad
				ref="adpc1"
				v-if="isPC"
				:ads="adsensConfig.ad1"
				class="adpc1" />
			<Ad
				ref="adm1"
				v-if="!isPC"
				:ads="adsensConfig.ad1"
				class="adm1" />
			<Ad
				ref="adpc2"
				v-if="isPC"
				:ads="adsensConfig.ad2"
				class="adpc2" />
			<Ad
				ref="adm2"
				v-if="!isPC"
				:ads="adsensConfig.ad2"
				class="adm2" />
			<Ad
				v-if="!isPC"
				:ads="adsensConfig.ad7"
				ref="adm7"
				class="adm7" />

			<a
				:data-gamename="item.gameName"
				:class="[0, 7, 12, 22].includes(index) ? 'big' : ''"
				v-for="(item, index) in curGames"
				:key="item.gameName + index"
				class="item as gameIcon gameDom"
				@click="goGame(item.gameName)">
				<img
					loading="lazy"
					decoding="async"
					class="icon"
					:src="item.logoUrl"
					alt="" />
				<div
					class="gameName"
					v-if="deviceType !== 'mobile'">
					{{ item.gameName }}
				</div>
				<div class="co"></div>
			</a>
		</div>
		<!-- 移动端时，点击加载触发load事件 -->
		<!-- pc，切换页面pageChange -->
		<load-btn
			@load="load"
			:ifAllLoad="ifAllLoad"
			@pageChange="onPageChange"
			:currentPage="currentPage"
			:pageSize="112"
			:total="total" />

		<bottomCate />
		<Ad
			v-if="isPC"
			:ads="adsensConfig.ad3"
			class="adpc3"
			ref="adpc3" />
		<Ad
			v-if="!isPC"
			:ads="adsensConfig.ad3"
			class="adm3"
			ref="adm3" />
		<div class="intro">
			<h1>
				Why Choose
				<a
					href="/"
					style="color: rgb(40 171 250)">
					FunHub.games
				</a>
				?
			</h1>

			<h2>1. Extensive Game Library:</h2>
			<p>Our site features a diverse array of games across multiple genres, including action, puzzle, strategy, adventure, sports, and more. We continuously update our library with the latest titles to ensure there's always something new and exciting for you to play.</p>

			<h2>2. HTML5 Technology:</h2>
			<p>All our games are built using HTML5 technology, which means they are compatible with all modern web browsers and devices. Whether you're on a desktop, laptop, tablet, or smartphone, you can enjoy our games without any hassle.</p>

			<h2>3. User-Friendly Interface:</h2>
			<p>
				Navigating
				<a href="/">FunHub.games</a>
				is a breeze, thanks to our clean and intuitive interface. Easily browse through our game categories or use the search function to find your favorite games quickly.
			</p>

			<h2>4. No Registration Required:</h2>
			<p>Start playing immediately without the need to sign up. However, creating an account allows you to save your progress, track your achievements, and participate in our vibrant gaming community.</p>

			<h2>5. High-Quality Games:</h2>
			<p>
				We are committed to offering only the best games. Each game on
				<a href="/">FunHub.games</a>
				is carefully selected to ensure it meets our high standards for graphics, gameplay, and overall user experience.
			</p>

			<h2>6. Community and Social Features:</h2>
			<p>Join our community of gamers to share tips, strategies, and experiences. Compete in leaderboards and see how you rank against other players.</p>

			<h2>Popular Game Categories</h2>
			<ul>
				<li>
					<a href="/action-games">Action Games</a>
					: Experience the thrill of fast-paced action with our selection of shooters, fighting games, and more.
				</li>
				<li>
					<a href="/puzzle-games">Puzzle Games</a>
					: Challenge your mind with a variety of puzzles, including match-3 games, brainteasers, and more.
				</li>
				<li>
					<a href="/strategy-games">Strategy Games</a>
					: Test your strategic thinking with games that require planning, resource management, and tactical skills.
				</li>
				<li>
					<a href="/adventure-games">Adventure Games</a>
					: Embark on epic quests and explore fantastical worlds with our adventure games.
				</li>
				<li>
					<a href="/sports-games">Sports Games</a>
					: Enjoy realistic sports simulations, from soccer and basketball to racing and golf.
				</li>
				<li>
					<a href="/casual-games">Casual Games</a>
					: Perfect for a quick gaming session, our casual games are easy to pick up and play.
				</li>
				<li>
					<a href="/racing-games">Racing Games</a>
					: Speed through tracks and race to victory.
				</li>
				<li>
					<a href="/match-3-games">Match 3 Games</a>
					: Align tiles and achieve high scores.
				</li>
				<li>
					<a href="/shooting-games">Shooting Games</a>
					: Test your aim in action-packed scenarios.
				</li>
				<li>
					<a href="/number-games">Number Games</a>
					: Sharpen your math skills with fun challenges.
				</li>
			</ul>

			<h2>How to Get Started</h2>
			<ol>
				<li>
					<strong>Step 1: Browse the Library:</strong>
					Explore our extensive game library by browsing through different categories or using the search function.
				</li>
				<li>
					<strong>Step 2: Select a Game:</strong>
					Click on a game that interests you to open the game page, where you can read a brief description and view screenshots.
				</li>
				<li>
					<strong>Step 3: Start Playing:</strong>
					Click the "Play Now" button to launch the game directly in your browser.
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
import LoadBtn from '../components/loadBtn.vue'
import { mapState } from 'vuex'
import { getList } from '../api'
export default {
	components: { LoadBtn },
	data() {
		return {
			ob: null,
			allGames: [],
			curGames: [],
			currentPage: 1,
			total: 100,
			ifAllLoad: false,
			// 传递给head的
			oneGame: {
				gameName: '',
				logoUrl: '',
			},
		}
	},
	computed: {
		...mapState(['deviceType']),
	},
	watch: {
		allGames() {
			this.oneGame.gameName = this.curGames[23]?.gameName
			this.oneGame.logoUrl = this.curGames[23]?.logoUrl
		},
	},
	async beforeMount() {
		const query = this.$route.query
		if (Object.keys(query).length !== 0) {
			this.$store.state.query = query
		}
		console.log(this.$route.query)
		if (this.$store.state.deviceType === 'mobile') {
			await this.getList({
				pageSize: 24,
				pageNum: this.currentPage,
			})
			this.addCurGame()
		} else {
			await this.getList({
				pageSize: 112,
				pageNum: this.currentPage,
			})
		}
	},
	async mounted() {
		this.$store.state.channel = this.$route.path
		// 设置观察者监控icon出现次数
		setTimeout(() => {
			this.startOb()
			this.observerAdExpose()
		})
	},
	methods: {
		// 观察广告曝光
		observerAdExpose() {
			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.observerAdExpose, 500) // 延迟再次尝试
				return
			}
			let _that = this
			const o = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((item) => {
						// isIntersecting 表示是否出现
						if (item.isIntersecting) {
							_that.$logEvent('ad_show')
							console.log('adshow')
							// 如果只需要判断出现一次，就要观察到后就unobserve停止观察
							observer.unobserve(item.target)
						}
					})
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: 0.1,
				},
			)
			let adEl = document.querySelectorAll('[data-ad-status="filled"]')
			if (adEl.length) {
				console.log('获得广告元素,开始监听', adEl)
				adEl?.forEach((e) => {
					o.observe(e)
				})
				this.setupIframeTracking()
			} else {
				// console.log('未找到广告，等待再次尝试');
				setTimeout(this.observerAdExpose, 3000) // 延迟再次尝试
			}
		},
		// 追踪广告点击
		setupIframeTracking() {
			// let _this = this;
			console.log('ready to track iframe')
			const iframes = document.querySelectorAll('iframe')
			class IframeTracker {
				constructor(vues, resolution = 200) {
					this.vues = vues // 保存 Vue 实例
					this.resolution = resolution
					this.iframes = []
					this.interval = null
					this.activeIframe = null // 存储最近被点击的iframe
					this.setupVisibilityTracking()
				}

				addIframe(element, callback) {
					const adContainer = element.closest('ins.adsbygoogle')
					const iframe = {
						element: element,
						callback: callback,
						hasTracked: false,
						adSlot: adContainer ? adContainer.dataset.adSlot : null,
					}
					this.iframes.push(iframe)

					if (!this.interval) {
						this.interval = setInterval(() => this.checkClick(), this.resolution)
					}
				}

				checkClick() {
					const activeElement = document.activeElement
					if (activeElement) {
						this.iframes.forEach((iframe) => {
							if (activeElement === iframe.element && !iframe.hasTracked) {
								iframe.callback(iframe.element)
								iframe.hasTracked = true
								this.activeIframe = iframe // 更新最近被点击的iframe
							}
						})
					}
				}

				setupVisibilityTracking() {
					const hiddenProperty = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
					const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')

					const onVisibilityChange = () => {
						if (!document[hiddenProperty]) {
							console.log('页面激活状态,返回页面?')
							this.activeIframe = null // 重置最近被点击的iframe记录
						} else {
							console.log('页面非激活状态')

							if (this.activeIframe) {
								// 上报逻辑
								this.trackEvent('ad_iframe_click_leave', 'click', {
									'data-ad-slot': this.activeIframe.adSlot,
								})
							}
						}
					}

					this.iframeVisibilityTracker = onVisibilityChange
					document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker)
				}

				trackEvent(eventName, eventValue, dataAttributes) {
					// 事件追踪逻辑
					console.log(`${eventName} with value ${eventValue} on`, dataAttributes)

					this.vues.$eventrack(eventName, eventValue, {
						...dataAttributes,
					})
				}
			}
			const tracker = new IframeTracker(this)
			iframes.forEach((iframe) => {
				tracker.addIframe(iframe, (iframeElement) => {
					console.log('Iframe 点击触发了!', iframeElement)
					const adContainer = iframeElement.closest('ins.adsbygoogle')
					if (adContainer) {
						// TikTok 上报
						this.ttTrack(adContainer.dataset.adSlot, 'click', 'ad_iframe_click')
					}
					this.$logEvent('ad_click')
				})
			})
		},
		/** TikTok 上报函数 */
		ttTrack(id, type = 'expose', name = 'ad') {
			console.log('tiktok track')
			ttq.track('ClickButton', {
				value: '100', // number. Value of the order or items sold. Example: 100.
				currency: 'USD', // string. The 4217 currency code. Example: "USD".
				contents: [
					{
						content_id: id, // string. ID of the product. Example: "1077218".
						content_type: type, // string. Either product or product_group.
						content_name: name, // string. The name of the page or product. Example: "shirt".
					},
				],
			})
		},
		startOb() {
			const o = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((item) => {
						// isIntersecting 表示是否出现
						if (item.isIntersecting) {
							this.$logEvent('game_icon_expose:' + item.target.dataset.gamename, 'expose')
							observer.unobserve(item.target)
						}
					})
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: 0.1,
				},
			)
			this.ob = o
			let games = document.querySelectorAll('.gameDom')
			for (const el of games) {
				o.observe(el)
			}
		},
		//广告相关start
		async loadAdSenseScript() {
			// 其他需要在初次加载时执行的操作
			// 先检查广告是否已经加载
			if (window.adsbygoogle && window.adsbygoogle.loaded) {
				// 检查广告是否已经加载
				console.log('Adsense script already loaded.')
				this.$eventrack('adscript_loaded', 'expose')
				this.loadAdWithDelay()
				return // 如果已加载，直接返回
			}
			await this.loadScriptConditionally()
		},
		loadScriptConditionally() {
			console.log(this.$store.state.adsensConfig)
			// 判断广告脚本URL是否存在
			if (!this.$store.state.adsensConfig?.scriptUrl) {
				console.log('广告脚本的URL不存在,终止加载广告外链')
				this.$eventrack('no_adscript_config', 'expose')
				return
			}
			// 检查该脚本是否已经被添加
			const existingScript = document.querySelector(`script[src="${this.$store.state.adsensConfig.scriptUrl}"]`)
			if (existingScript) {
				this.$eventrack('adscript_exist', 'expose')
				console.log('脚本已存在，无需重新添加')
				return
			}

			console.log('准备插入脚本')
			const script = document.createElement('script')
			script.src = this.$store.state.adsensConfig?.scriptUrl
			script.crossOrigin = 'anonymous'
			script.async = true

			const header = document.getElementsByTagName('head')[0]
			header.appendChild(script)

			this.$eventrack('adscript_add_success', 'expose')
			script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
			console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
		},
		loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
			}, 500)
		},
		async displayAd() {
			await this.$nextTick()

			// 获取所有 ads 元素的 refs，过滤掉非广告的 ref
			const adsElements = Object.entries(this.$refs)
				.filter(([key]) => key.startsWith(this.isPC ? 'adpc' : 'adm')) // 只选择以 'ad' 开头的 refs
				.flatMap(([, ref]) => ref) // 展开并获取所有元素

			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.displayAd, 500) // 延迟再次尝试
				return
			}

			console.log(adsElements) // 检查是否包含 <ins> 标签
			adsElements.forEach((ad) => {
				console.log(ad) // 输出每个广告元素
				console.log('ready to push')
				;(window.adsbygoogle = window.adsbygoogle || []).push({})
			})
		},
		// 每次请求一分页新数据，再把10个放到当前数组
		async load() {
			// 全部加载完就不请求
			await this.getList({
				pageSize: 24,
				pageNum: ++this.currentPage,
			})
			this.addCurGame()
			setTimeout(() => {
				this.startOb()
			})
		},
		// 移动端点击加载时，把获取到的allGame游戏添加进当前curGame数组
		addCurGame() {
			// 初次加载24个
			if (this.curGames.length === 0) {
				this.curGames.push(...this.allGames.splice(0, 24))
			} else {
				// 每次加载添加10个进当前
				this.curGames.push(...this.allGames.splice(0, 10))
				if (this.allGames.length === 0) {
					this.ifAllLoad = true
					return
				}
			}
		},
		// mobile:获取新分页数据添加进allGames;pc新分页替换curGames
		async getList({ type = '', pageSize = 24, pageNum = 1 }) {
			this.$store.state.collocationLoading = true
			// console.log(type, 'pageSize', pageSize, 'pageNum', pageNum)
			let arr = []
			const { infoList, total } = await getList(arguments[0])
			arr = infoList
			this.total = total
			if (this.deviceType === 'mobile') {
				this.allGames.push(...arr)
			} else {
				this.curGames.splice(0, this.curGames.length, ...arr)
				// console.log(this.curGames)
			}
			this.$store.state.collocationLoading = false
			this.$logEvent('page_home', 'expose')
		},
		async onPageChange(curIndex) {
			this.currentPage = curIndex
			await this.getList({ pageNum: this.currentPage, pageSize: 112 })
			setTimeout(() => {
				window.scrollTo(0, 0)
			})
			this.startOb()
		},
	},
}
</script>
<style lang="scss" scoped>
.home-grid {
	.item {
		overflow: hidden;
		position: relative;
		border-radius: rem(20);
		box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
		// background-color: #fff;
		&.big {
			grid-column: span 2 / span 2;
			grid-row: span 2 / span 2;
		}
	}
	.icon {
		height: 100%;
		width: 100%;
		vertical-align: middle;
		position: absolute;
		inset: 0px;
		object-fit: cover;
	}
}
.intro {
	ul li::before {
		color: rgb(85, 169, 243) !important;
	}
	background-color: #fff;
	padding: rem(30);
	margin-top: rem(50);
	border-radius: rem(20);

	h1 {
		color: #002b51 !important;
		font-size: rem(40);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	h2 {
		color: #002b51 !important;
		font-size: rem(30);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	p {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		letter-spacing: rem(1);
	}
	li {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		letter-spacing: rem(1);
	}
	a {
		text-decoration: none;
		color: rgb(85, 169, 243);
		position: relative;
	}
	a:hover {
		color: #31699e;
		text-decoration: underline;
	}
}
.gameIcon:hover {
	transform: translateY(-4px);
	.gameName {
		display: block;
	}
}
.gameIcon .co {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.5));
	opacity: 0;
}
.gameIcon:hover .co {
	animation: coAni 0.2s ease-out forwards;
}
.gameIcon:hover .gameName {
	animation: nameAni 0.3s ease-out forwards;
}
@keyframes coAni {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes nameAni {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 10px;
	}
}
.gameName {
	display: none;
	text-align: center;
	color: #fff;
	font-family: Roboto;
	font-size: 13px;
	position: absolute;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
}
.big .gameName {
	font-size: 20px !important;
}
</style>
