<template>
	<div
		v-show="(!((isPC && fullUrl.indexOf('click-games') >= 0) || (isPC && $route.name === 'home'))) && $route.path.indexOf('channel') === -1"
		class="headRight bg-white box"
		:class="isPC && fullUrl.indexOf('click-games') < 0 && fullUrl.indexOf('-games') >= 0 ? 'col-span-2' : 'as'">
		<a
			style="width: 100%; height: 100%"
			v-show="!isPC && ($route.name === 'home' || $route.name === 'detail')"
			class="item as gameIcon overflow-hidden"
			:href="'/click-games/' + gameName.toLowerCase().replaceAll(' ', '-')">
			<img
				class="icon"
				:src="logoUrl"
				alt="" />
		</a>
		<div
			class="gameIcon"
			v-show="fullUrl.indexOf('click-games') < 0 && fullUrl.indexOf('-games') >= 0">
			{{ $route.path.substring(1).replaceAll('-', ' ') }}
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		gameName: {
			default: '',
		},
		logoUrl: {
			logoUrl: '',
		},
	},
	data() {
		return {}
	},

	methods: { ...mapGetters(['getDeviceType']) },
	computed: {
		fullUrl() {
			console.log(this.$route.path)
			return this.$route.path
		},
		isPC() {
			return this.getDeviceType() === 'desktop'
		},
	},
}
</script>

<style lang="scss" scoped>
.headRight {
	// padding: .2rem;
	justify-content: center;
	display: flex;
	align-items: center;
	text-align: center;
	color: #002b51;
	font-family: Roboto;
	font-size: rem(32);
	font-style: normal;
	font-weight: 600;
	line-height: 100%; /* 125% */
}
.gameType {
	padding: 0 !important;
}
.gameIcon {
	border-radius: rem(20);
}
.box {
	box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
	border-radius: rem(20);
	background: #fff;
}
@media screen and (orientation: landscape) {
}
</style>
