// src/mixins/dataMixin.js
import { mapState } from "vuex"
export default {
	computed: {
		...mapState(['adsensConfig']),
		isPC () {
			return this.$store.state.deviceType === 'desktop'
		}
	},
	methods: {
		goGame(name) {
			console.log(this.$route.path);
			this.$router.push({
				name: this.$route.path.indexOf('channel') !== -1 ? 'channeldetail' : 'homedetail',
				params: {
					gameName: name?.toLowerCase().replaceAll(' ', '-')
				},
				query: Object.keys(this.$store.state.query).length === 0 ? undefined : this.$store.state.query
			})
		}
	}
}
