<template>
	<div
		id="app"
		:class="`app_device_detect_is_${deviceType}`"
		:style="{
			backgroundImage: `url(${require('@/assets/bg.svg')})`,
		}">
		<!-- 第一种方式的通用loading -->
		<Loading v-show="collocationLoading" />

		<div class="wrapper">
			<keep-alive>
				<router-view :key="$route.path" />
			</keep-alive>
		</div>

		<Bottom></Bottom>
	</div>
</template>

<script>
import TopHead from './components/headLeft.vue'
import Bottom from './components/Bottom.vue'
import { mapState, mapMutations } from 'vuex'
import Loading from '@/components/Loading.vue'
import '@/css/main.scss'
export default {
	name: 'App',
	components: {
		Loading,
		TopHead,
		Bottom,
	},

	beforeMount() {
		const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		if (flag) {
			this.setDeviceType('mobile')
		} else {
			this.setDeviceType('desktop')
		}
		console.log(this.$store.state.deviceType)
	},
	computed: {
		...mapState({
			collocationLoading: (state) => state.collocationLoading,
			deviceType: (state) => state.deviceType,
		}),
	},
	methods: {
		...mapMutations(['setDeviceType']),
	},
	created() {
		let debug = this.$global.GetQueryString('db')
		if (debug) {
			this.$store.commit('setDebug', true)
		}
	},
}
</script>
<style lang="scss" scoped>
#app {
	display: flex;
	flex-direction: column;

	background-color: rgb(131, 255, 231);

	// background-position: center center;
	// /* 背景图不平铺 */
	// background-repeat: no-repeat;
	// /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
	// background-attachment: fixed;
	// /* 让背景图基于容器大小伸缩 */
	// background-size: cover;

	padding: rem(98) rem(30) 0;
	min-height: 100vh;
	.wrapper {
		flex: 1;
	}
}
@media screen and (orientation: landscape) {
	#app {
		padding: rem(30) rem(30) 0;
	}
}
</style>
