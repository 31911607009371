import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	// 修改 原型对象中的push方法
	return originalPush.call(this, location).catch((err) => err)
}
// 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			title: 'Free Online Games - Play Now!',
			description: 'Discover funhub.games, your go-to site for top HTML5 games. Enjoy a vast selection of genres with no downloads needed. Play anytime, anywhere on any device. Dive into endless fun and new adventures today!',
		},
		component: () => import('../pages/index.vue'),
	},
	{
		path: '/:channel(channel[1-9][0-9]{0,1})',
		name: 'channel',
		meta: {
			title: 'Free Online Games - Play Now!',
			description: 'Discover joyit.fun, your go-to site for top HTML5 games. Enjoy a vast selection of genres with no downloads needed. Play anytime, anywhere on any device. Dive into endless fun and new adventures today!',
		},
		component: () => import('../pages/index.vue'),
	},
	{
		path: '/click-games/:gameName',
		name: 'homedetail',
		component: () => import('../pages/gameDetail.vue'),
	},
	{
		path: '/:channel(channel[1-9][0-9]{0,1})/click-games/:gameName',
		name: 'channeldetail',
		component: () => import('../pages/gameDetail.vue'),
	},
	{
		path: '/about',
		name: 'about',
		meta: {
			title: 'About funhub.games',
			description: 'At funhub.games, we bring you the best in H5 gaming. Our platform aggregates a wide variety of HTML5 games that you can enjoy directly in your browser without the need for downloads or installations.',
		},
		component: () => import('../pages/about.vue'),
	},
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			title: `Privacy Policy of funhub.games`,
			description: `At funhub.games, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information.`,
		},
		component: () => import('../pages/privacy.vue'),
	},

	{
		path: '/contact',
		name: 'contact',
		meta: {
			title: `Contact funhub.games`,
			description: `If you have any questions, concerns, or feedback, please feel free to reach out to us. We are here to help!`,
		},
		component: () => import('../pages/contact.vue'),
	},

	{
		path: '/hot-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Hot Games - Play Games Online for Free',
			description: 'Want to play Hot Games? Play Hot Games and You Can also Find More Free Hot Games on funhub.games',
		},
	},
	{
		path: '/new-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'New Games - Play Games Online for Free',
			description: 'Want to play New Games? Play New Games and You Can also Find More Free New Games on funhub.games',
		},
	},
	{
		path: '/action-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Action Games - Play Games Online for Free',
			description: 'Want to play Action Games? Play Action Games and You Can also Find More Free Action Games on funhub.games',
		},
	},
	{
		path: '/casual-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Casual Games - Play Games Online for Free',
			description: 'Want to play Casual Games? Play Casual Games and You Can also Find More Free Casual Games on funhub.games',
		},
	},
	{
		path: '/puzzle-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Puzzle Games - Play Games Online for Free',
			description: 'Want to play Puzzle Games? Play Puzzle Games and You Can also Find More Free Puzzle Games on funhub.games',
		},
	},
	{
		path: '/racing-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Racing Games - Play Games Online for Free',
			description: 'Want to play Racing Games? Play Racing Games and You Can also Find More Free Racing Games on funhub.games',
		},
	},
	{
		path: '/sport-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Sport Games - Play Games Online for Free',
			description: 'Want to play Sport Games? Play Sport Games and You Can also Find More Free Sport Games on funhub.games',
		},
	},
	{
		path: '/match-3-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Match 3 Games - Play Games Online for Free',
			description: 'Want to play Match 3 Games? Play Match 3 Games and You Can also Find More Free Match 3 Games on funhub.games',
		},
	},
	{
		path: '/adventure-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Adventure Games - Play Games Online for Free',
			description: 'Want to play Adventure Games? Play Adventure Games and You Can also Find More Free Adventure Games on funhub.games',
		},
	},
	{
		path: '/shooting-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Shooting Games - Play Games Online for Free',
			description: 'Want to play Shooting Games? Play Shooting Games and You Can also Find More Free Shooting Games on funhub.games',
		},
	},
	{
		path: '/strategy-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Strategy Games - Play Games Online for Free',
			description: 'Want to play Strategy Games? Play Strategy Games and You Can also Find More Free Strategy Games on funhub.games',
		},
	},
	{
		path: '/number-games',
		component: () => import('../pages/indexCate.vue'),
		meta: {
			title: 'Number Games - Play Games Online for Free',
			description: 'Want to play Number Games? Play Number Games and You Can also Find More Free Number Games on funhub.games',
		},
	},

	{
		path: '/:404',
		name: '404',
		component: () => import('../pages/error.vue'),
	},
]

export default function createRouter() {
	const router = new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
	// router.beforeEach((to, from, next) => {
	// 	console.log(to.path)
	// 	if (to.path === '/channel1')
	// 	{
	// 		console.log(4);
	// 		next('/')
	// 	}
	// })
	return router
}
